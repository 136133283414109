import * as React from 'react';
import { NavLink, matchPath } from 'react-router-dom';
import * as styles from './Header.scss';
var isIndexActive = function (match, location) {
    return (matchPath(location.pathname, { path: '/:sticker?' }) !== null &&
        matchPath(location.pathname, { path: '/add-sticker' }) === null);
};
var Header = function () { return (<div className={styles.header}>
        <NavLink to="/" isActive={isIndexActive} className={styles.link} activeClassName={styles.linkActive}>
            <h1 className={styles.logo}>GESTICKER</h1>
        </NavLink>
        <NavLink to="/add-sticker" className={styles.link} activeClassName={styles.linkActive}>
            Add sticker
        </NavLink>
    </div>); };
export default Header;
