var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { withFormik, Field, ErrorMessage, } from 'formik';
import * as moment from 'moment';
import * as React from 'react';
import { getUsefulImageData } from '../../helpers/exif';
import Button from '../Button/Button';
import DatePicker from '../DatePicker/DatePicker';
import ImageDropzone from '../ImageDropzone/ImageDropzone';
import LocationPicker from '../LocationPicker/LocationPicker';
import RadioSelect from '../RadioSelect/RadioSelect';
import TextInput from '../TextInput/TextInput';
import * as styles from './AddSticker.scss';
import StickerSchema from './StickerSchema';
import history from '../../history';
function Label(_a) {
    var children = _a.children, name = _a.name, htmlFor = _a.htmlFor, _b = _a.optional, optional = _b === void 0 ? false : _b;
    return (<label htmlFor={htmlFor}>
            <div className={styles.rowHeading}>{children}</div>
            {optional && <div className={styles.rowMessage}>Optional</div>}
            <ErrorMessage name={name} render={function (msg) { return <div className={styles.rowError}>{msg}</div>; }}/>
        </label>);
}
function FormRow(_a) {
    var id = _a.id, name = _a.name, label = _a.label, optional = _a.optional, disabled = _a.disabled, renderInput = _a.renderInput;
    id = id || name;
    return (<Field name={name} render={function (_a) {
        var field = _a.field, form = _a.form;
        return (<div className={disabled ? styles.rowDisabled : styles.row}>
                    {label && (<Label name={name} htmlFor={id} optional={optional}>
                            {label}
                        </Label>)}
                    {renderInput({ field: __assign({}, field, { id: id, disabled: disabled }), form: form })}
                </div>);
    }}/>);
}
function AddStickerForm(props) {
    var handleSubmit = props.handleSubmit, setFieldValue = props.setFieldValue, values = props.values;
    function handleImageChange(image) {
        return __awaiter(this, void 0, void 0, function () {
            var data, e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setFieldValue('image', image);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, getUsefulImageData(image)];
                    case 2:
                        data = _a.sent();
                        if (data.author) {
                            setFieldValue('author', data.author);
                        }
                        if (data.createDate) {
                            setFieldValue('date', moment(data.createDate));
                        }
                        if (data.coords) {
                            setFieldValue('coords', data.coords);
                        }
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _a.sent();
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    }
    return (<form onSubmit={handleSubmit}>
            <FormRow name="image" renderInput={function (_a) {
        var field = _a.field;
        return (<ImageDropzone image={field.value} onChange={handleImageChange}/>);
    }}/>
            <FormRow name="type" label="Did you place or spot this sticker?" disabled={!values.image} renderInput={function (_a) {
        var field = _a.field;
        return (<RadioSelect options={{
            placed: 'Placed',
            spotted: 'Spotted',
        }} {...field}/>);
    }}/>
            <div className={styles.rowContainer}>
                <FormRow name="author" label="Photo author" disabled={!values.image} renderInput={function (_a) {
        var field = _a.field;
        return <TextInput {...field}/>;
    }}/>
                <FormRow name="date" label="Photo date" disabled={!values.image} renderInput={function (_a) {
        var field = _a.field;
        return (<DatePicker {...field} onChange={function (value) {
            return setFieldValue(field.name, value);
        }}/>);
    }}/>
            </div>
            <FormRow name="coords" label="Location" disabled={!values.image} renderInput={function (_a) {
        var field = _a.field;
        return (<LocationPicker coords={field.value} onChange={function (value) { return setFieldValue(field.name, value); }}/>);
    }}/>
            <div className={!values.image ? styles.rowDisabled : styles.row}>
                <Button primary type="submit">
                    Add sticker
                </Button>
            </div>
        </form>);
}
export default withFormik({
    mapPropsToValues: function () {
        return {
            image: null,
            type: '',
            author: '',
            date: null,
            coords: null,
        };
    },
    validationSchema: StickerSchema,
    handleSubmit: function (values) {
        return __awaiter(this, void 0, void 0, function () {
            var data, response, result;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        data = new FormData();
                        data.append('image', values.image);
                        data.append('type', values.type);
                        data.append('author', values.author);
                        data.append('date', values.date ? values.date.toISOString() : '');
                        data.append('lng', values.coords[0].toString());
                        data.append('lat', values.coords[1].toString());
                        return [4 /*yield*/, fetch('/api/stickers', {
                                method: 'POST',
                                body: data,
                            })];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.json()];
                    case 2:
                        result = _a.sent();
                        if (response.status === 200) {
                            history.push("/" + result.sticker.id);
                        }
                        else {
                            console.log(result.errors);
                        }
                        return [2 /*return*/];
                }
            });
        });
    },
})(AddStickerForm);
