var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from 'react';
import * as moment from 'moment';
import * as styles from './StickerPopup.scss';
var StickerPopup = /** @class */ (function (_super) {
    __extends(StickerPopup, _super);
    function StickerPopup() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    StickerPopup.prototype.scheduleUpdate = function () {
        this.props.scheduleUpdate();
    };
    StickerPopup.prototype.render = function () {
        var _a = this.props, popperRef = _a.popperRef, style = _a.style, placement = _a.placement, arrowProps = _a.arrowProps, sticker = _a.sticker;
        return (<div ref={popperRef} style={style} data-placement={placement} className={styles.popup}>
                <div className={styles.content}>
                    <img className={styles.image} src={"/uploads/" + sticker.image.filename} alt=""/>

                    <div>
                        {sticker.type === 'placed' ? 'Placed' : 'Spotted'}
                        {sticker.author && <> by {sticker.author}</>}
                        {sticker.date && (<> on {moment(sticker.date).format('LL')}</>)}
                    </div>
                </div>
                <div className={styles.arrow} {...arrowProps}/>
            </div>);
    };
    return StickerPopup;
}(React.Component));
export default StickerPopup;
