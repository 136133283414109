var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/src/stylesheets/datepicker-cssmodules.scss';
import * as styles from './DatePicker.scss';
var DatePicker = function (_a) {
    var value = _a.value, onChange = _a.onChange, otherProps = __rest(_a, ["value", "onChange"]);
    return (<ReactDatePicker {...otherProps} selected={value} onChange={onChange} className={styles.input} dateFormat="LL"/>);
};
export default DatePicker;
