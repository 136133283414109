var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as classNames from 'classnames';
import * as React from 'react';
import * as styles from './Button.scss';
var Button = function (_a) {
    var primary = _a.primary, children = _a.children, otherProps = __rest(_a, ["primary", "children"]);
    return (<button className={classNames(styles.button, primary && styles.primary)} {...otherProps}>
        {children}
    </button>);
};
export default Button;
