var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import ReactMapboxFactory, { Marker, Cluster } from 'react-mapbox-gl';
import { Manager, Reference, Popper } from 'react-popper';
import history from '../../history';
import StickerPopup from '../StickerPopup/StickerPopup';
import StickerMarker from '../StickerMarker/StickerMarker';
import ClusterMarker from '../ClusterMarker/ClusterMarker';
import * as styles from './Map.scss';
var Mapbox = ReactMapboxFactory({
    accessToken: process.env.MAPBOX_ACCESS_TOKEN,
});
var defaultCenter = [5.4877141, 51.4473811];
var defaultZoom = 14;
function clusterMarkerFactory(coords, pointCount) {
    return <ClusterMarker coordinates={coords} pointCount={pointCount}/>;
}
export default function Map(_a) {
    var match = _a.match;
    // Reference to the underlying Mapbox Map, used for getting current zoom
    var mapRef = useRef(null);
    var popupRef = useRef(null);
    var _b = useState({
        center: defaultCenter,
        zoom: [defaultZoom],
    }), transform = _b[0], setTransform = _b[1];
    // Initial moving method is 'jumpTo' to immediately center on the active
    // sticker. It will be set to 'flyTo' when clicking a marker for a nicer
    // transition.
    var _c = useState('jumpTo'), movingMethod = _c[0], setMovingMethod = _c[1];
    var _d = useState([]), stickers = _d[0], setStickers = _d[1];
    // Find active sticker
    var activeStickerId = match.params.sticker && parseInt(match.params.sticker, 10);
    var activeSticker = stickers.find(function (sticker) { return sticker.id === activeStickerId; });
    // Fetch stickers once when component is mounted
    useEffect(function () {
        function fetchStickers() {
            return __awaiter(this, void 0, void 0, function () {
                var response, result;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, fetch('/api/stickers')];
                        case 1:
                            response = _a.sent();
                            return [4 /*yield*/, response.json()];
                        case 2:
                            result = _a.sent();
                            setStickers(result.stickers.map(function (sticker) { return (__assign({}, sticker, { date: new Date(sticker.date) })); }));
                            return [2 /*return*/];
                    }
                });
            });
        }
        fetchStickers();
    }, []);
    // Pan to and zoom in on the active sticker
    useEffect(function () {
        if (activeSticker) {
            setTransform(__assign({}, transform, { center: activeSticker.coords.slice(), zoom: [
                    // Only zoom in to default zoom, do not zoom out
                    Math.max(mapRef.current ? mapRef.current.getZoom() : defaultZoom, defaultZoom),
                ] }));
        }
    }, [activeSticker]);
    return (<div className={styles.container}>
            <Manager>
                <Mapbox style="mapbox://styles/mapbox/streets-v11" containerStyle={{ flex: 'auto' }} onStyleLoad={function (map) { return (mapRef.current = map); }} renderChildrenInPortal movingMethod={movingMethod} {...transform} onMove={function () {
        if (popupRef.current) {
            popupRef.current.scheduleUpdate();
        }
    }} onClick={function (map, e) {
        // Close active sticker popup when clicking outside it
        var target = e.originalEvent.target;
        if (target.classList.contains('mapboxgl-canvas')) {
            history.push('/');
        }
    }}>
                    <>
                        <Cluster ClusterMarkerFactory={clusterMarkerFactory} zoomOnClick zoomOnClickPadding={50}>
                            {stickers
        .filter(function (sticker) { return sticker !== activeSticker; })
        .map(function (sticker) { return (<StickerMarker key={sticker.id} coordinates={sticker.coords} sticker={sticker} onClick={function (e) {
        // Transition to the clicked sticker
        setMovingMethod('flyTo');
        history.push("/" + sticker.id);
    }}/>); })}
                        </Cluster>
                        {activeSticker && (<Marker coordinates={activeSticker.coords}>
                                <Reference>
                                    {function (refProps) { return <div {...refProps}/>; }}
                                </Reference>
                            </Marker>)}
                    </>
                </Mapbox>

                {activeSticker && (<Popper placement="top">
                        {function (_a) {
        var ref = _a.ref, otherProps = __rest(_a, ["ref"]);
        return (<StickerPopup ref={popupRef} popperRef={ref} sticker={activeSticker} {...otherProps}/>);
    }}
                    </Popper>)}
            </Manager>
        </div>);
}
