import * as React from 'react';
import ReactMapboxFactory from 'react-mapbox-gl';
import StickerMarker from '../StickerMarker/StickerMarker';
import * as styles from './LocationPicker.scss';
var Mapbox = ReactMapboxFactory({
    accessToken: process.env.MAPBOX_ACCESS_TOKEN,
    dragRotate: false,
    pitchWithRotate: false,
});
var defaultCenter = [5.4877141, 51.4473811];
var LocationPicker = function (_a) {
    var coords = _a.coords, onChange = _a.onChange;
    return (<div className={styles.wrapper}>
            <Mapbox style="mapbox://styles/mapbox/streets-v11" containerStyle={{ width: '100%', height: '100%' }} center={coords || defaultCenter} onClick={function (map, e) {
        onChange([e.lngLat.lng, e.lngLat.lat]);
    }} renderChildrenInPortal>
                {coords && <StickerMarker coordinates={coords}/>}
            </Mapbox>
        </div>);
};
export default LocationPicker;
