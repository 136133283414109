import * as React from 'react';
import { Marker } from 'react-mapbox-gl';
import * as styles from './StickerMarker.scss';
var StickerMarker = function (_a) {
    var coordinates = _a.coordinates, onClick = _a.onClick;
    return (<Marker coordinates={coordinates} onClick={onClick} style={{ width: 0, height: 0 }}>
        <div className={styles.marker}/>
    </Marker>);
};
export default StickerMarker;
