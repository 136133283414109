import * as React from 'react';
import { Marker } from 'react-mapbox-gl';
import * as styles from './ClusterMarker.scss';
var ClusterMarker = function (_a) {
    var pointCount = _a.pointCount, coordinates = _a.coordinates, onClick = _a.onClick;
    return (<Marker coordinates={coordinates} onClick={onClick}>
        <div className={styles.marker}>{pointCount}</div>
    </Marker>);
};
export default ClusterMarker;
